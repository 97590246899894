import {
  VictoryAxis, VictoryBar, VictoryChart, VictoryLabel,
} from 'victory';

type DataPoints = {
  [key:string]:any
};

export default function BarGraph({ data }: { data: DataPoints[] }) {
  if (data.length === 0) {
    return <div>No data available</div>;
  }
  const [xKey, yKey] = Object.keys(data[0]);
  const yValues = data.map((d) => d[xKey]);
  const yTicks = yValues.map((y) => y);

  return (
    <div className="w-full m-2">
      <VictoryChart
        domainPadding={20}
      >
        <VictoryLabel
          text="Number of Households Contacted Who Went on to Claim by Month"
          style={{
            fontSize: 14,
          }}
          dx={28}
          dy={18}
        />
        <VictoryAxis
    // tickValues specifies both the number of ticks and where
    // they are placed on the axis
          tickValues={yValues}
          tickFormat={yTicks}
          tickLabelComponent={<VictoryLabel style={{ fontSize: 14 }} />}
        />

        <VictoryAxis
          dependentAxis
          label="Claims"
          style={{
            axis: { stroke: 'transparent' },
            axisLabel: {
              fontSize: 14,
              padding: 30,
            },
            tickLabels: {
              fontSize: 14,
            },
          }}
    // tickFormat specifies how ticks should be displayed
          tickFormat={(x:number) => {
            if (x >= 1000) {
              return `${Math.round(x / 1000)}K`;
            }
            if (x >= 100) {
              return Math.round(x).toString();
            }
            if (x >= 10) {
              return Math.round(x).toString();
            }
            return Math.round(x).toString();
          }}
        />
        <VictoryLabel text="Month(s) Since Campaign" dy={290} dx={160} style={{ fontSize: 14 }} />
        <VictoryBar
          style={{ data: { fill: '#10623b' } }}
          data={data}
          x={xKey}
          y={yKey}
        />
      </VictoryChart>
    </div>
  );
}
