enum Role {
  SuperAdmin = 'SuperAdmin',
  OrganisationAdmin = 'OrganisationAdmin',
  Viewer = 'Viewer',
}

export enum Channel {
  BackendLetter = 'print_and_post',
  Letter = 'Letter',
  SMS = 'SMS',
}

export default Role;
