import { SMSTemplate } from '../../types/global';

export default function SMSGallery({ smsTemplateList, handleSms }:{ smsTemplateList:SMSTemplate[], handleSms:(template:SMSTemplate)=>void }) {
  return (
    <div id="sms-templates-container" className="m-2 gap-2">
      <p className="text-sm font-thin m-2">Choose your SMS Message</p>
      <div className="flex flex-wrap gap-2">
        {Array.isArray(smsTemplateList) && smsTemplateList.map((template) => (
          <div key={template.id} className="">
            <button
              type="button"
              className="hover:bg-pipgreen hover:text-white rounded-2xl bg-pipgrey-light rounded-bl-none px-4 py-3"
              onClick={() => handleSms(template)}
            >
              {template.template}
            </button>
          </div>

        ))}
      </div>
    </div>
  );
}
