/* eslint-disable react/no-array-index-key */
import { ChangeEvent, useState } from 'react';
import ChatBox from '../components/policy/chatBox';
import SplitLayout from '../components/splitLayout';
import AgentMessage from '../components/policy/agentMessage';
import UserMessage from '../components/policy/userMessage';
import Api from '../services/api';
import customIcons from '../utils/icons';
import { Message } from '../types/global';
import sanitize from '../utils/formatters';

export default function SupportPolicy() {
  const [messages, setMessages] = useState<Message[]>([
  ]);
  const [inputContent, setInputContent] = useState<string>('');
  const [errorState, setErrorState] = useState<string | null>(null);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorState(null);
    const userMessage = sanitize(inputContent);
    const newUserMessage:Message = { role: 'user', content: [{ text: userMessage }] };

    setMessages((currentMessages) => [...currentMessages, newUserMessage]);
    setInputContent('');

    try {
      const response = await Api.post('/api/policy-bot', {
        content: [...messages, newUserMessage],
      });
      const assistantText = response.content;
      const newMessage = { role: 'assistant', content: [{ text: assistantText }] };
      setMessages((currentMessages) => [...currentMessages, newMessage]);
    } catch (error) {
      console.error('There was an error submitting the message: ', error);
      setErrorState('There was an error handling your request.');
      setTimeout(() => setErrorState(null), 5000);
    }
  };

  const handleQuestionInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputContent(value);
    setErrorState(null);
    if (value.length >= 200) {
      setErrorState('Please keep your question under 200 characters');
    } else {
      setErrorState(null);
    }
  };

  return (
    <SplitLayout leftWeight="w-1/2 md:w-1/3" rightWeight="w-1/2 h-full md:w-3/4">
      <p>Predefined Questions</p>
      <ChatBox>
        <div className="flex flex-col items-start gap-4">
          <div className="p-6 bg-pipgrey-light self-center  justify-around w-full text-center">
            <h1 className="text-xl self-center font-semibold"> Policy and Support AI</h1>
            <p>Let&apos;s see if we can find you the right information</p>
          </div>
          <AgentMessage index={-1} key="initial assistant message" content="Hello, I'm your assistant. I'm here to help you understand UK benefit policy. Let's get started!" />
          {messages.map((message, index) => {
            const textExists = message.content[0]?.text;
            if (textExists) {
              if (message.role === 'user') {
                return <UserMessage key={`${index}-UM`} content={message.content[0].text} index={index} />;
              }
              return <AgentMessage key={`${index}-AM`} content={message.content[0].text} index={index} />;
            }
            return null;
          })}
        </div>

        <form
          className="flex flex-row w-11/12 rounded-full gap-2 justify-center border border-gray-300 bg-pipbg-mobile items-center"
          onSubmit={onSubmit}
        >
          <label htmlFor="userQuestion">
            {' '}
          </label>
          <textarea
            id="userQuestion"
            placeholder="Type your question here..."
            value={inputContent}
            maxLength={200}
            onChange={(e) => handleQuestionInput(e)}
            className="text-pipgrey-dark bg-pipbg-mobile text-xs lg:text-lg text-md w-full h-fit min-h-[20px] md:min-h-[50px] max-h-[10px] resize-none overflow-scroll t-2 md:pl-4 md:pr-4 p2 mt-2 mb-2 rounded-full  focus:ring-0 focus:outline-none placeholder:text-pipgrey-dark"
          />

          { inputContent.length > 0 ? (
            <div className="m-2 bg-pipstatus-success text-white hover:bg-pipgreen-dark  p-2 rounded-full w-8 h-8 md:w-12 md:h-12">
              <button className="w-full h-full flex items-center justify-center" type="submit">
                {customIcons.arrowUpIcon({ width: 4, height: 4, className: 'md:w-8 md:h-8' })}
              </button>
            </div>
          ) : (
            <div className="m-2 bg-pipgrey text-white p-2 rounded-full w-8 h-8 md:w-12 md:h-12">
              <button className="w-full h-full flex items-center justify-center" type="submit" disabled>
                {customIcons.arrowUpIcon({ width: 4, height: 4, className: 'md:w-8 md:h-8' })}
              </button>
            </div>

          )}
        </form>
        {errorState && <p className=" text-red-800 font-normal">{errorState}</p>}
      </ChatBox>
    </SplitLayout>

  );
}
