import { create } from 'zustand';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactNode } from 'react';
import { ToastStoreState } from '../../types/global';

const defaultToastOptions: ToastOptions = {
  position: 'bottom-right',
  autoClose: 6000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const useToastStore = create<ToastStoreState>((set) => ({
  toasts: [],
  showToast: (message, type) => {
    const id = Date.now();
    const messageString = message as string | ReactNode;
    toast(message, {
      ...defaultToastOptions,
      type,
      toastId: id,
    });
    set((state) => ({
      ...state,
      toasts: [...state.toasts, { id, message: messageString, type }],
    }));
  },
  removeToast: (id) => {
    toast.dismiss(id);
    set((state) => ({
      toasts: state.toasts.filter((t) => t.id !== id),
    }));
  },
}));

export default useToastStore;
