import { useLoaderData } from 'react-router-dom';
import Api from '../services/api';
import { PensionCreditCampaignSummary } from '../types/global';
import HeadlineCard from '../components/home/headlineCard';
import SplitLayout from '../components/splitLayout';
import BarGraph from '../components/home/barGraph';
import FallbackGraph from '../components/home/fallbackGraph';
import { formatDateToString, formatSnapshot } from '../utils/formatters';
import SmallButton from '../components/smallButton';

export function loader({ params }:{ params:{ id: number } }) {
  const { id } = params;
  try {
    const campaignData = Api.post(`/api/campaign/pension-credit-campaign-summary?campaign_id=${id}`);
    return campaignData;
  } catch (error) {
    console.error('error:', 'Failed to fetch data');
    return undefined;
  }
}

export default function CampaignSummary() {
  // TODO: update response to include monthly data for graph, ticket: RD-2084
  const data:any[] = [];
  const campaignData = useLoaderData() as PensionCreditCampaignSummary;
  const formattedSanpshotDate = formatSnapshot(campaignData.campaign_details.snapshot);

  // TODO:BACKEND: response model needs to change, till then using claim data, ticket: RD-2081
  const headlines = [{
    text: 'Total Households Contacted',
    number: campaignData.campaign_details.number_of_recipients,
  },
  {
    text: 'Total Households Who Have Claimed',
    number: campaignData.campaign_impact.number_of_households_claiming_pension_credit,
  },
  {
    text: 'Total Carer households',
    number: campaignData.campaign_impact.number_of_carer_households,
  },
  {
    text: 'Annual Impact',
    number: campaignData.campaign_impact.annual_pc_value.toLocaleString(),
  },
  {
    text: 'Lifetime Impact',
    number: campaignData.campaign_impact.lifetime_pc_value.toLocaleString(),
    children: <div className="font-normal text-xs">definition of lifetime impact</div>,
  }];
  const reportEnds = new Date(campaignData.campaign_details.date_sent).setMonth(new Date(campaignData.campaign_details.date_sent).getMonth() + 6);
  return (
    <div id="campaign-container" className="flex flex-col w-full p-4 overflow-auto">
      <div className="flex flex-row justify-between border-b-2 items-center">
        <h1 id="campaign-summary-header" className="m-2 p-4 text-header font-medium text-pipgreen-dark ">Campaign Report</h1>
      </div>
      <div className="h-fit">
        <div id="campaign-details-container" className="rounded-xl bg-pipbg-mobile flex flex-col md:flex-row m-2 justify-between">
          <div className="flex flex-col mx-4 p-2 justify-between">
            <h2 className="font-semibold text-lg">Campaign Details</h2>
            <div>
              <p className="">{`Campaign Name: ${campaignData.campaign_details.name}`}</p>
              <p>{`Data from: ${formattedSanpshotDate}`}</p>
              <p>{`Campaign Sent On: ${formatDateToString(campaignData.campaign_details.date_sent)}`}</p>
              <p>{`Communications Channel: ${campaignData.campaign_details.channel === 'print_and_post' ? 'Letter' : 'SMS'}`}</p>
            </div>
          </div>
          {campaignData.campaign_details.date_sent ? (
            <p className="mx-4 p-2">
              Report Closes:
              <span>{` ${new Date(reportEnds).toDateString()}` }</span>
            </p>
          ) : ''}

        </div>
        <div id="campaign-impact-header" className="flex flex-col md:flex-row mx-4 px-2 align-middle md:items-center justify-between">
          <h2 className="mx-2 font-semibold text-lg">Campaign Impact</h2>
          <div className="flex flex-col w-40 lg:items-end m-2">
            <SmallButton text="Export Data" isGreen onClick={() => console.log('clicked!')} />
          </div>
        </div>
        <SplitLayout leftWeight="border-none shadow-none" rightWeight="border-none shadow-none">
          <div id="campaign-impact-headlines" className="m-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {headlines.map((headline) => (
              <HeadlineCard
                key={headline.text}
                text={headline.text}
                number={headline.number}
              >
                {headline.children}
              </HeadlineCard>
            ))}
          </div>
          <div id="campaign-impact-graph">
            {data && data.length > 0 ? <BarGraph data={data} /> : <FallbackGraph />}
          </div>
        </SplitLayout>
      </div>
    </div>
  );
}
