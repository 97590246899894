/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-props-no-spreading */
import { ComponentType, ElementType, FC } from 'react';
import Role from '../types/enums';
import { User } from '../types/global';
// Role component wrapper needs versatile type so that it can be used for all components while still being type safe
const withRoleAccess = (allowedRoles: string[]) => <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P & User> | ElementType => {
  const WithRoleAccess: FC<P & User> = ({ role, ...props }) => {
    if (allowedRoles.includes(role)) {
      return <WrappedComponent {...props as P} />;
    }
    return null;
  };

  return WithRoleAccess;
};

const withSuperAdminAccess = () => <P extends object>(WrappedComponent: ComponentType<P>): ComponentType<P & User> | ElementType => {
  const WithRoleAccess: FC<P & User> = ({ role, ...props }) => {
    if (role === Role.SuperAdmin) {
      return <WrappedComponent {...props as P} />;
    }
    return null;
  };

  return WithRoleAccess;
};

export const adminAccess = withRoleAccess([Role.SuperAdmin, Role.OrganisationAdmin]);
export const superAdminAccess = withSuperAdminAccess();
