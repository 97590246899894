import { useState } from 'react';
import Api from '../services/api';
import customIcons from '../utils/icons';
import useToastStore from './app-layout/toastStore';

export default function ExportCSV({ id, data, icon }: { id: string | number | undefined, data: any, icon:boolean }) {
  const [isLoading, setIsLoading] = useState(false);

  const getCSV = async () => {
    setIsLoading(true);
    try {
      const response = await Api.post('/api/temporary-csv', data);
      const hyperlink = (
        <div>
          download csv
          {' '}
          <a
            href={response}
            download="your-file-name.csv"
            className="underline text-blue-500 ml-1"
            onClick={(e) => {
              e.preventDefault();
              window.open(response, '_blank');
            }}
          >
            link
          </a>
          !
        </div>
      );
      useToastStore.getState().showToast(hyperlink, 'success');
    } catch (error) {
      useToastStore.getState().showToast(error instanceof Error ? error.message : 'An error occurred', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex-end font-thin">
      <button
        onClick={getCSV}
        disabled={isLoading}
        id={`${id}-export`}
        type="button"
        className="flex flex-row justify-center items-center rounded-md pl-2 pr-2 shadow border hover:border-pipgrey-md p-1 hover:bg-pipgrey-light"
      >
        {icon
          ? <span>{customIcons.downloadIcon}</span>
          : <p className="mx-1">{isLoading ? 'Loading...' : 'Export CSV'}</p>}
      </button>
    </div>
  );
}
