import customIcons from './icons';

const campaignSteps = [{
  icon: customIcons.folderOpenIcon,
  step: 'Choose name and campaign type',
  info: 'Create a name for this campaign and choose your take up campaign type eg. pension credit',
}, {
  icon: customIcons.sendIcon,
  step: 'Choose Communication Channel',
  info: 'We currently support letter campaigns, SMS coming soon',
}];
export const previewLetterStep = {
  icon: customIcons.previewIcon,
  step: 'Preview Letter',
  info: 'By clicking next, it will take you to the next page allowing you to preview the letter',
};
export const sendLetterStep = {
  icon: customIcons.sendIcon,
  step: 'Send for Review',
  info: 'By clicking send for review, you confirm the details are ready to be sent to Policy in Practice for review',
};

export default campaignSteps;
