import ExportCSV from '../components/exportCsv';
import { IconColumn } from '../types/global';

// keeping this as record string so that we can add more buttons in the future
const actionColumns: Record<string, IconColumn> = {
  Export: {
    title: 'Export',
    component: ExportCSV,

  },
};

export default actionColumns;
