export default function sanitize(userInput: string): string {
  if (typeof userInput === 'string') {
    // removing < and > from the string
    return userInput.replace(/</g, '&lt;').replace(/>/g, '&gt;');
  } return '';
}
export const formatSnapshot = (snapshot: string): string => {
  const firstDate = `20${snapshot.slice(2, 4)}-${snapshot.slice(0, 2)}-01`;
  return new Date(firstDate).toLocaleString('default', { month: 'long', year: 'numeric' });
};

// replace spaces with underscores and make lowercase
export const snakeCase = (word:string) => word.replace(/ /g, '_').toLowerCase();

export const formatDateToString = (date: Date): string => { const newDate = new Date(date); return newDate.toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' }); };
