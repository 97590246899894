import { HeadlineCardProps } from '../../types/global';
import PercentageChange from './percentageChange';

export default function HeadlineCard({
  text, number, percentageChange, children,
}: HeadlineCardProps) {
  return (
    <div className="flex flex-col m-1 p-2 font-normal w-full justify-evenly border-r-2 border-pipgrey-light last:border-0 shadow-[0_0_10px_rgba(0,0,0,0.1)] rounded-lg">
      <p className="text-wrap text-xs">
        {text}
      </p>
      <span className="text-lg font-semibold my-1 ml-1">{number}</span>
      { children || ''}
      {percentageChange ? (<PercentageChange percentageChange={percentageChange} />) : ''}
    </div>
  );
}
