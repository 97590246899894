import { useEffect, useState } from 'react';
import CollapseButton from './collapseButton';
import customIcons from '../../utils/icons';
import { MenuItem } from '../../types/global';
import Role from '../../types/enums';
import MenuItems from './menuItems';
import Logo from '../logo';
import useAuth from '../../utils/auth';
import UserText from '../userText';

export default function SideMenu() {
  const authUser = useAuth();
  const [isExpanded, setIsExpanded] = useState(window.innerWidth >= 768);
  const [userToggled, setUserToggled] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
    setUserToggled(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (!userToggled) {
        if (window.innerWidth < 768) {
          setIsExpanded(window.innerWidth >= 768);
        }
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [userToggled]);

  const menuItems: MenuItem[] = [
    { name: 'Home', icon: customIcons.homeIcon, path: '/' },
    { name: 'Campaigns', icon: customIcons.sendIcon, path: '/campaigns' },
    { name: 'Settings', icon: customIcons.settingsIcon, path: '/settings' },
    { name: 'Support', icon: customIcons.supportIcon, path: authUser.role === Role.SuperAdmin ? '/support' : '/support/product' },
    { name: 'name', icon: customIcons.logoutIcon, path: '/logout' },
  ];
  return (
    <>
      <div
        id="side-menu"
        className={`sm:block h-screen bg-white text-pipgrey-dark ${isExpanded ? 'min-w-full ' : 'min-w-min'}`}
      >
        <div className="flex h-full min-h-0 flex-col pt-2 pb-2">
          <div className={`${isExpanded ? 'm-2' : 'ml-2 mt-2 mb-2'}`}>
            {/* OPTIONAL: may need to add in prop for classNames here but we may need to test out varying logos to test the UI */}
            {isExpanded
              ? <Logo src="/pip_green_logo.svg" alt="Policy in Practice" className="" />
              : <Logo src="/pip_green_logo_notext.svg" alt="Policy in Practice" className="" />}
          </div>

          <nav className="flex h-full w-full flex-col mt-2 mb-2 p-2 font-nunito text-sm">
            <ul
              id="navigation-menuitems"
              className="flex flex-col justify-between"
            >
              {menuItems.slice(0, 2).map((item) => (
                <MenuItems
                  key={item.name}
                  item={item}
                  isExpanded={isExpanded}
                  className="flex flex-row items-center pr-4 py-2 hover:bg-gray-50"
                />
              ))}
            </ul>
            <ul
              id="support-menuitems"
              className="flex flex-col mt-auto font-thin text-xs"
            >
              {menuItems.slice(2, 4).map((menuItem) => (
                <MenuItems
                  item={menuItem}
                  key={menuItem.name}
                  isExpanded={isExpanded}
                  className="flex flex-row items-center pr-4 p-1  hover:bg-gray-50"
                />
              ))}
            </ul>
          </nav>
          <div className="text-xs flex flex-grow">
            {authUser.email ? <UserText icon={customIcons.logoutIcon} state={isExpanded} title={authUser.email.toString().split('.', 1)[0]} subtitle={authUser.email.toString()} /> : <div />}
            <button id="logout-button" type="button" className="m-1 mb-2" onClick={() => { window.location.href = `${import.meta.env.VITE_BACKEND_URL}/backend/auth/logout`; }}>
              <span aria-label="logout">{customIcons.logoutIcon}</span>
            </button>
          </div>
        </div>
      </div>
      <CollapseButton isExpanded={isExpanded} toggle={toggleMenu} />
    </>
  );
}
