/* eslint-disable react/jsx-props-no-spreading */
import { Form, redirect, useLoaderData } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AdvancedInput from '../components/advancedInput';
import SplitLayout from '../components/splitLayout';
import { createCampaign } from '../utils/campaign';
import customIcons from '../utils/icons';
import Steps from '../components/campaigns/steps';
import campaignSteps, { previewLetterStep } from '../utils/campaignSteps';
import sanitize, { formatSnapshot, snakeCase } from '../utils/formatters';
import useCampaignStore from '../components/campaigns/campaignStore';
import Api from '../services/api';
import { CampaignInformation } from '../types/global';
import AdvancedCheckbox from '../components/advancedCheckbox';
import { Channel } from '../types/enums';

export function loader() {
  try {
    const latestSnapshot = Api.get('/api/latest-snapshot');
    return latestSnapshot;
  } catch (error) {
    console.error('error:', error);
    return undefined;
  }
}

export async function action({ request, params }:{ request:any, params:any }) {
  const formData = await request.formData();
  const campaignInformation: CampaignInformation = {
    snapshot: formData.get('snapshot'),
    campaignType: formData.get('campaignType'),
    campaignName: formData.get('campaignName'),
    communicationChannel: formData.get('communicationChannel'),
    excludeHouseholds: formData.has('excludeHouseholds'),
    excludeHouseholdsByMonth: formData.get('excludeHouseholdsByMonth') || null,
  };
  const newCampaign = await createCampaign(campaignInformation);
  if (params.impactTheme) {
    return redirect(`/campaigns/${params.impactTheme}/new/${newCampaign.id}`);
  }
  return redirect(`/campaigns/new/${newCampaign.id}`);
}

export default function NewCampaign() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const data = useLoaderData();
  const { snapshot } = useLoaderData() as { snapshot: string };
  const [eligibleHouseholds, setEligibleHouseholds] = useState<number | null>(null);
  const [householdError, setHouseholdError] = useState<JSX.Element | null>(null);

  const selected = useCampaignStore((state) => state.selected);
  const setSelected = useCampaignStore((state) => state.setSelected);
  const displayExcludeHouseholds = false;

  useEffect(() => {
    const fallBackMessage = (
      <div className="flex flex-col items-right justify-center text-right">
        <h3 className="mx-2 px-2 font-bold text-pipgreen-dark">No eligible households found</h3>
        <p className="max-w-md px-4">Please contact your account manager or email helpdesk@policyinpractice.co.uk</p>
      </div>
    );
    const getEligibleHouseholdCount = async (
      campaignName:string,
      snapshotDate:string,
      campaignType:string,
      communicationChannel:string,
    ) => {
      if (!selected.campaignName || !selected.campaignType || !selected.communicationChannel) return;
      try {
        const response = await Api.post('/api/eligible-households-with-pii', {
          campaign_name: campaignName,
          benefit_name: snakeCase(campaignType),
          snapshot: snapshotDate,
          channel_name: communicationChannel === Channel.Letter ? Channel.BackendLetter : communicationChannel.toLowerCase(),
        });
        setEligibleHouseholds(response);
      } catch (error) {
        setHouseholdError(fallBackMessage);
      }
    };
    getEligibleHouseholdCount(selected.campaignName, snapshot, selected.campaignType, selected.communicationChannel);
  }, [selected.campaignName, selected.campaignType, snapshot, selected.communicationChannel]);

  const handleChange = (event:any) => {
    const {
      name, value, type, checked,
    } = event.target;

    if (value === 'default') return;

    if (type === 'checkbox') {
      setSelected(name, checked);
    } else {
      const cleanValue = sanitize(value);
      setSelected(name, cleanValue);
    }
  };

  const dropdownData = {
    id: 'campaignType',
    options: ['Pension Credit', 'Healthy Start', 'Attendance Allowance'],
  };

  const channelDropdownData = {
    id: 'Channel',
    options: [Channel.Letter, Channel.SMS],
  };

  const titleObject = {
    title: 'Campaign Manager',
    icon: customIcons.sendIcon,
  };

  const checkboxOptions = {
    id: 'excludeHouseholds',
    label: 'Would you like to exclude households that have recently been contacted?',
    text: 'Exclude Households',
  };
  const months = [...Array(24).keys()].map((month) => (month >= 1 ? `${month + 1} Months` : 'Month'));
  const excludeHouseholdsByMonth = {
    id: 'excludeHouseholdsByMonth',
    options: months,
  };
  const steps = [...campaignSteps, previewLetterStep];
  const eligibleHouseholdMessage = `${eligibleHouseholds} households eligible to receive ${selected.communicationChannel} campaign`;

  return (
    <div className="flex flex-col w-full p-4 overflow-auto">
      <h1 className="m-2 p-4 text-header font-medium text-pipgreen-dark border-b-2">Create new campaign</h1>
      <SplitLayout leftWeight="" rightWeight="">
        <Steps steps={steps} titleObject={titleObject} selected={selected} />
        <div className="w-full h-full mx-2 p-2 flex flex-col overflow-auto">
          <h2 className="text-2xl font-medium text-left">Choose Your Campaign</h2>
          <h3 className="text-sm font-normal ml-2">
            Running a campaign will use your latest data from LIFT:
            <strong>
              {` ${formatSnapshot(snapshot)}`}
            </strong>
          </h3>
          <Form id="create-campaign" className="w-full" method="post">
            <AdvancedInput
              type="text"
              label="Campaign Name"
              name="campaignName"
              id="campaignName"
              value={selected.campaignName}
              onChange={(e: any) => handleChange(e)}
              required
            />
            <AdvancedInput
              type="dropdown"
              label="Campaign Type"
              {...dropdownData}
              name="campaignType"
              id="campaignType"
              value={selected.campaignType}
              onChange={(event:any) => handleChange(event)}
              required
            />
            <AdvancedInput
              type="dropdown"
              label="Communication Channel"
              {...channelDropdownData}
              name="communicationChannel"
              id="communicationChannel"
              onChange={(event:any) => handleChange(event)}
              value={selected.communicationChannel}
              required
            />
            <input type="hidden" name="snapshot" value={snapshot} />

            {displayExcludeHouseholds ? (
              <AdvancedCheckbox
                fieldsetId="excludeHouseholds"
                fieldsetTitle={checkboxOptions.label}
                options="Exclude Households"
                onChange={(event:any) => handleChange(event)}
                selected={selected.excludeHouseholds}
              />
            ) : ''}

            {selected.excludeHouseholds && (
            <AdvancedInput
              type="dropdown"
              label="For the previous"
              {...excludeHouseholdsByMonth}
              name="excludeHouseholds"
              id="excludeHouseholdsByMonth"
              onChange={(e: any) => handleChange(e)}
              required
            />
            )}
          </Form>
          {eligibleHouseholds && !householdError
            ? <p className="mt-auto ml-auto mx-2 font-thin text-sm">{eligibleHouseholdMessage}</p>
            : ''}
        </div>
      </SplitLayout>
      <div className="w-full mt-auto mb-6 pr-4 pt-4 flex justify-center md:justify-end">
        {householdError || <button type="submit" form="create-campaign" className={`${!eligibleHouseholds ? 'bg-pipgrey cursor-not-allowed' : 'bg-pipgreen-dark hover:bg-opacity-90'} w-2/3 md:w-1/3 p-2 rounded-lg text-pipgrey-light`}>Next</button>}
      </div>
    </div>
  );
}
