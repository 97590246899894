export default function LetterPreview({ url }: { url: any }) {
  const fallBackMessage = (
    <div className="flex flex-col items-center justify-center h-full text-center">
      <h3 className="mx-2 p-2 font-bold text-pipgreen-dark">Letter Template Not Found</h3>
      <p className="max-w-md px-4">It looks like we do not have your template on file. Please contact your account manager or email helpdesk@policyinpractice.co.uk</p>
    </div>
  );
  return (url
    ? (
      <>
        <h3 className="mx-2 p-2 font-bold text-pipgreen-dark text-center">
          Letter Preview
        </h3>
        <iframe
          src={url}
          className="w-full h-screen border-0"
          title="PDF Preview"
        />
      </>
    ) : fallBackMessage
  );
}
